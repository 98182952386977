body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sayongbangbub, .geabalja {
  color: #997000;
  font-size: 20px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
}

.sayongbangbub:hover, .geabalja:hover {
  color: black;
}

.navGJM1 {
  color: #997000;
  font-size: 20px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
  margin-right: 560px;
}

.navGJM1:hover {
  color: black;
}

.navGJM2 {
  color: #997000;
  font-size: 20px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
  margin-left: 400px;
}

.navGJM2:hover {
  color: black;
}

.navGJM3 {
  color: #997000;
  font-size: 20px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
}

.navGJM3:hover {
  color: black;
}

.navGJM4 {
  color: #997000;
  font-size: 20px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
  text-align: center;
}

.navGJM4:hover {
  color: black;
}


.geabaljaPagefont{
  color: #997000;
  font-size: 40px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
  text-align: center;
}

.geabaljaPagefont1{
  color: #997000;
  font-size: 40px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
  text-align: center;
  margin-top: -250px;
  margin-right: -220px;
}

.geabaljaPagefont2{
  color: #997000;
  font-size: 40px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
  text-align: center;
  margin-top: -250px;
  margin-left: -190px;
}

.geabaljaPagefont3{
  color: #997000;
  font-size: 40px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
  text-align: center;
  margin-top: -250px;
  margin-bottom: 410px;
  margin-left: -10px;
}


.sayongbangbubFont1{
  color: #997000;
  font-size: 40px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
  text-align: center;
  margin-bottom: 100px;
  margin-left: 500px;
}

.sayongbangbubFont2{
  color: black;
  font-size: 22px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
  text-align: center;
  margin-top: -220px;
}

.sayongbangbubSentence{
  color: #997000;
  font-size: 20px;
  font-family: 'Gowun Batang', serif;
  text-align: center;
  text-decoration: none;
}

.gistock {
  color: #997000;
  font-size: 50px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
}

.yangsicExchange{
  color: #997000;
  font-size: 27px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 30px;
}

.img-fluid{
  padding-top: 5px;
  padding-bottom: 250px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 0px;
}

.img-fluid2{
  padding-top: 5px;
  padding-bottom: 250px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 80px;
  width: 900px;
  height: 850px;
}

.bg-warning-subtle{
  padding-top: 120px;
}

.myBtn{
  text-align: center;
}

.fileUpload{
  text-align: center;
  font-family: 'Gowun Batang', serif;
  font-size: large;
  margin-left: 200px;
}

.form-control{
  margin-left: 90px;
}

.imgCenter
{
  text-align: center;
  margin-right: -230px;
}

.imgCenter2
{
  text-align: center;
  margin-left: -200px;
}

.imgCenter3
{
  text-align: center;
}

.sayongbangbubImage{
  padding-left: 500px;
}

.sayongbangbubImage2{
  padding-left: 100px;
}

.sayongbangbubImage3{
  padding-left: 650px;
}

.sayongbangbubImage4{
  padding-left: 450px;
}

.sayongbangbubImage5{
  padding-left: 605px;
}

.sayongbangbubImage6{
  padding-left: 470px;
}

.sayongbangbubImage7{
  padding-left: 530px;
}

.sayongbangbubImage8{
  padding-left: 500px;
}

.sayongbangbubImage9{
  padding-left: 300px;
}

.sayongbangbubImage10{
  padding-left: 500px;
}

.sayongbangbubImage11{
  padding-left: 500px;
}

.navBarFont{
  color: #997000;
  font-size: 40px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
  margin-left: 130px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.navBarFont2{
  color: #997000;
  font-size: 20px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
  margin-left: 1000px;
  margin-bottom: -2px;

}

.navBarFont2:hover{
  color: black;
}

.navBarFont3{
  color: #997000;
  font-size: 20px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
  margin-left: 50px;
  margin-bottom: -2px;

}

.navBarFont3:hover{
  color: black;
}


.navBarFont4{
  color: #997000;
  font-size: 20px;
  font-family: 'Gowun Batang', serif;
  text-decoration: none;
  margin-left: 50px;
  margin-bottom: -2px;

}

.navBarFont4:hover{
  color: black;
}

.noScroll{
  overflow-x: hidden;
}